import React from 'react';

interface IProps {
  isCentered?: boolean;
  hasGradient?: boolean;
  gradientColor?: string;
  hasImage?: boolean;
  hasImageVertical?: boolean;
  hasIcon?: boolean;
  customClass?: string;
  children?: React.ReactNode;
}

export const Box: React.FC<IProps> = ({
  isCentered,
  hasGradient,
  gradientColor,
  hasImage,
  hasImageVertical,
  hasIcon,
  customClass,
  children
}) => {
  return (
    <div
      className={`box ${isCentered ? 'centered' : ''} ${
        hasGradient ? 'with-gradient' : ''
      } ${gradientColor ? gradientColor : ''} ${hasImage ? 'with-image' : ''} ${
        hasImageVertical ? 'with-image-vertical' : ''
      } ${hasIcon ? 'with-icon' : ''} ${customClass ? customClass : ''}`}
    >
      {children}
    </div>
  );
};
