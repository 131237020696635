import React from 'react';
// import { Tab, Tabs } from 'react-bootstrap';
// import { Box } from '../../modules/common/components/box';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Row } from 'react-bootstrap';
import { CategoryCard } from '../../modules/cs/main/components/category-card';

const IndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page'} game="cs">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Counter Side wiki and tier list</h1>
        <h2>
          Prydwen.gg is an official wiki for Counter Side Global. Check our tier
          lists and reviews for characters, ships, and operators available in
          Global and KR versions of game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Shortcuts" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Beginner guide"
          link="/counter-side/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/counterside/categories/guide_beginner.png"
              alt="Beginner guide"
            />
          }
        />
        <CategoryCard
          title="Intro to RTA concepts"
          link="/counter-side/guides/rta-concepts"
          image={
            <StaticImage
              src="../../images/counterside/categories/guide_pvphistory.png"
              alt="Siege Playbook"
            />
          }
        />
        <CategoryCard
          title="Shops and currencies"
          link="/counter-side/guides/shops-and-currencies"
          image={
            <StaticImage
              src="../../images/counterside/categories/guide_shops.png"
              alt="Shops and currencies"
            />
          }
        />
        <CategoryCard
          title="Who to reroll for"
          link="/counter-side/guides/reroll"
          image={
            <StaticImage
              src="../../images/counterside/categories/guide_reroll.png"
              alt="Who to reroll for"
            />
          }
        />
        <CategoryCard
          title="Rearm system"
          link="/counter-side/guides/rearm-system"
          image={
            <StaticImage
              src="../../images/counterside/categories/guide_rearm.png"
              alt="Rearm system"
            />
          }
        />
        <CategoryCard
          title="Basic gearing"
          link="/counter-side/guides/basic-gearing"
          image={
            <StaticImage
              src="../../images/counterside/categories/guide_gearing.png"
              alt="Beginner gearing"
            />
          }
        />
        <CategoryCard
          title="Advanced gearing"
          link="/counter-side/guides/advanced-gearing"
          image={
            <StaticImage
              src="../../images/counterside/categories/category_gearbuilder.png"
              alt="Advanced gearing"
            />
          }
        />
        <CategoryCard
          title="Relic and latent gear"
          link="/counter-side/guides/relic-and-latent-gear"
          image={
            <StaticImage
              src="../../images/counterside/categories/guide_latent.png"
              alt="Relic and latent gear"
            />
          }
        />
        <CategoryCard
          title="PVP Gearing"
          link="/counter-side/guides/pvp-gearing"
          image={
            <StaticImage
              src="../../images/counterside/categories/category_pvpgearing.png"
              alt="PVP Gearing"
            />
          }
        />
      </Row>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default IndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Counter Side | Prydwen Institute"
    description="Prydwen.gg is an official wiki for Counter Side Global. Check our guides, tier lists and reviews for characters, ships, and operators available in Global and KR versions of game."
    game="cs"
  />
);
