import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Link } from 'gatsby';
import { Row, Col, Button } from 'react-bootstrap';
import { Box } from './box';
import { useAuth0 } from '@auth0/auth0-react';
// import { LoginButton } from './login-button';

import './community-section.scss';

export const CommunitySection: React.FC = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <Row className="community">
      {!isAuthenticated ? (
        <>
          <Col lg="12" xl="6">
            <Box hasImage hasGradient>
              <StaticImage
                src="../../../images/community_discord.png"
                alt="Join our Discord!"
              />
              <h3>
                Meet the team on <span>Discord</span>
              </h3>
              <p>
                Join the ever-growing community on our Discord (over 53000
                members) and meet the team!
              </p>
              <Button
                variant="primary"
                href="https://discord.gg/prydwen"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faDiscord} width="18" /> Join our
                community Discord
              </Button>
            </Box>
          </Col>
          <Col lg="12" xl="6">
            <Box hasImage hasGradient gradientColor="red">
              <StaticImage
                src="../../../images/community_patreon_alt.png"
                alt="Supports us on Patreon!"
              />
              <h3>
                Support Prydwen on <span>Ko-Fi</span>
              </h3>
              <p>
                If you love what we are doing and you would like to support us,
                you can do it via Ko-Fi and gain some unique benefits in return!
              </p>
              <Button
                variant="primary"
                href="https://ko-fi.com/antillar"
                target="_blank"
                className="ko-fi"
                rel="noreferrer"
              >
                <StaticImage src="../../../images/kofi.webp" alt="Ko-Fi" />{' '}
                Support us on Ko-Fi
              </Button>
            </Box>
          </Col>
        </>
      ) : (
        <Col lg="12" xl="12">
          <Box hasImageVertical hasGradient gradientColor="blue">
            <div className="left">
              <h3>
                Meet the team on <span>Discord</span>
              </h3>
              <p>
                Join the ever-growing community on our Discord (over 53000
                members) and meet the team!
              </p>
              <Button
                variant="primary"
                href="https://discord.gg/prydwen"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon icon={faDiscord} width="18" /> Join our
                community Discord
              </Button>
            </div>
            <div className="right">
              <StaticImage
                src="../../../images/community_discord.png"
                alt="Join our Discord!"
              />
            </div>
          </Box>
        </Col>
      )}
    </Row>
  );
};
